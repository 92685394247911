import React, { useEffect, useState } from 'react';
import { IconButton, CircularProgress } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const NaatAudioButton = ({ Naats }) => {
  const [currentIndex, setCurrentIndex] = useState(
    () => parseInt(localStorage.getItem('lastPlayedIndex'), 10) || 0 // Load the last index from localStorage
  );
  const [isPlaying, setIsPlaying] = useState(
    () => JSON.parse(localStorage.getItem('isPlaying')) || false // Load the last play status
  );
  const [isLoading, setIsLoading] = useState(false);
  const [audioElement, setAudioElement] = useState(null);

  useEffect(() => {
    if (audioElement) {
      // Set the audio source
      audioElement.src = Naats[currentIndex]?.url;
      audioElement.load();
      setIsLoading(true);

      // Handle when the audio is ready
      audioElement.oncanplaythrough = () => {
        setIsLoading(false);
        if (isPlaying) {
          audioElement.play().catch((error) => {
            console.error("Error playing audio:", error);
            setIsPlaying(false);
          });
        }
      };

      // Handle when the audio ends
      audioElement.onended = () => {
        playNextNaat();
      };
    }
  }, [currentIndex, audioElement, Naats, isPlaying]);

  const playNextNaat = () => {
    const nextIndex = (currentIndex + 1) % Naats.length; // Loop back to the first Naat
    setCurrentIndex(nextIndex);
    localStorage.setItem('lastPlayedIndex', nextIndex); // Save the last played index
  };

  const toggleAudio = () => {
    if (audioElement) {
      if (isPlaying) {
        audioElement.pause();
        setIsPlaying(false);
        localStorage.setItem('isPlaying', false);
      } else if (!isLoading) {
        audioElement.play().catch((error) => {
          console.error("Error playing audio:", error);
          setIsPlaying(false);
        });
        setIsPlaying(true);
        localStorage.setItem('isPlaying', true);
      }
    }
  };

  useEffect(() => {
    const audio = new Audio();
    audio.volume = 0.1; // Set the volume to 10%
    setAudioElement(audio);

    // Auto-play if a last played index exists and it was playing
    const lastIndex = parseInt(localStorage.getItem('lastPlayedIndex'), 10);
    const wasPlaying = JSON.parse(localStorage.getItem('isPlaying'));

    if (!isNaN(lastIndex)) {
      setCurrentIndex(lastIndex);
      setIsPlaying(wasPlaying || false);
      if (wasPlaying) {
        audio.src = Naats[lastIndex]?.url;
        audio.load();
        audio.play().catch((error) => console.error("Error auto-playing audio:", error));
      }
    }

    return () => {
      audio.pause();
      audio.src = '';
      audio.remove();
    };
  }, [Naats]);

  useEffect(() => {
    // Auto-play when the currentIndex changes
    if (audioElement && !isLoading && isPlaying) {
      audioElement.play().catch((error) => {
        console.error("Error playing audio:", error);
        setIsPlaying(false);
      });
    }
  }, [currentIndex, isLoading, audioElement, isPlaying]);

  return (
    <>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '20px',
            zIndex: 1000,
            color: '#004e8c',
          }}
        />
      ) : (
        <IconButton
          onClick={toggleAudio}
          style={{
            position: 'fixed',
            bottom: '20px',
            left: '20px',
            zIndex: 1000,
            backgroundColor: '#004e8c',
            color: '#FFFFFF',
            padding: '12px',
            borderRadius: '50%',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
          }}
        >
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
      )}
    </>
  );
};

export default NaatAudioButton;
