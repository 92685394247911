const baseUl="https://backend.halal-fly.de/";
const baseFileUrl="http://musicpassonline.com:3000/images"


const languages=[{value: "eng", label: "English"}, {value: "urdu", label: "Urdu"}];
const selectedLanguage="eng";


export {baseUl,baseFileUrl,languages,selectedLanguage};


