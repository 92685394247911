import React from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { CreditCard } from '@mui/icons-material';
import paypal from '../../assets/images/paypal.png';

const PaymentOptions = ({
  selectedMethod,
  setSelectedMethod,
  paymentType,
  setPaymentType,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSelectMethod = (method) => {
    setSelectedMethod(method); // Set selected payment method
  };

  const handlePaymentTypeChange = (event, newType) => {
    if (newType !== null) {
      setPaymentType(newType);
    }
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        mt: 4,
        px: isMobile ? 2 : 4,
        py: 2,
        backgroundColor: '#f9f9f9',
        borderRadius: '12px',
        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography
        variant={isMobile ? 'h6' : 'h5'}
        gutterBottom
        sx={{
          color: '#004e8c',
          fontWeight: 'bold',
          mb: 2,
        }}
      >
        Wählen Sie eine Zahlungsmethode
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {/* Stripe Payment Option */}
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth
            variant="outlined"
            sx={{
              borderColor: selectedMethod === 'Stripe' ? '#004e8c' : '#ddd',
              backgroundColor: selectedMethod === 'Stripe' ? '#e3f2fd' : '#fff',
              color: selectedMethod === 'Stripe' ? '#004e8c' : '#000',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textTransform: 'none',
              padding: isMobile ? '12px' : '16px',
              borderWidth: selectedMethod === 'Stripe' ? '2px' : '1px',
              transition: 'transform 0.3s ease, border-color 0.3s ease',
              '&:hover': {
                borderColor: '#004e8c',
                backgroundColor: '#e3f2fd',
                transform: 'scale(1.05)',
              },
            }}
            onClick={() => handleSelectMethod('Stripe')}
          >
            <CreditCard
              sx={{
                color: '#004e8c',
                fontSize: isMobile ? 24 : 50,
                mb: 1,
              }}
            />
            <Typography variant="button" fontWeight="bold">
              Mit Debit-/Kreditkarte bezahlen
            </Typography>
          </Button>
        </Grid>

        {/* PayPal Payment Option */}
        <Grid item xs={12} sm={6}>
          <Button
            fullWidth
            variant="outlined"
            sx={{
              borderColor: selectedMethod === 'PayPal' ? '#004e8c' : '#ddd',
              backgroundColor: selectedMethod === 'PayPal' ? '#e3f2fd' : '#fff',
              color: selectedMethod === 'PayPal' ? '#004e8c' : '#000',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textTransform: 'none',
              padding: isMobile ? '12px' : '16px',
              borderWidth: selectedMethod === 'PayPal' ? '2px' : '1px',
              transition: 'transform 0.3s ease, border-color 0.3s ease',
              '&:hover': {
                borderColor: '#004e8c',
                backgroundColor: '#e3f2fd',
                transform: 'scale(1.05)',
              },
            }}
            onClick={() => handleSelectMethod('PayPal')}
          >
            <Box
              component="img"
              src={paypal}
              alt="PayPal"
              sx={{
                width: isMobile ? '24px' : '50px',
                mb: 1,
                transition: 'transform 0.3s ease',
              }}
            />
            <Typography variant="button" fontWeight="bold">
              Mit PayPal bezahlen
            </Typography>
          </Button>
        </Grid>
      </Grid>

      {/* Payment Type Options */}
      {selectedMethod && (
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              color: '#004e8c',
              fontWeight: 'bold',
            }}
          >
            Wählen Sie die Zahlungsart
          </Typography>
          <ToggleButtonGroup
            value={paymentType}
            exclusive
            onChange={handlePaymentTypeChange}
            sx={{
              mt: 1,
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              gap: 2,
            }}
          >
            <ToggleButton
              value="full"
              sx={{
                flex: 1,
                textTransform: 'none',
                backgroundColor: paymentType === 'full' ? '#004e8c' : '#FFFFFF',
                color: paymentType === 'full' ? '#FFFFFF' : '#004e8c',
                borderColor: paymentType === 'full' ? '#004e8c' : '#ddd',
                fontWeight: 'bold',
                fontSize: isMobile ? '0.85rem' : '1rem',
                transition: 'transform 0.3s ease, background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#004e8c',
                  color: '#FFFFFF',
                  transform: 'scale(1.05)',
                },
              }}
            >
              Vollständige Zahlung
            </ToggleButton>
            <ToggleButton
              value="deposit"
              sx={{
                flex: 1,
                textTransform: 'none',
                backgroundColor: paymentType === 'deposit' ? '#004e8c' : '#FFFFFF',
                color: paymentType === 'deposit' ? '#FFFFFF' : '#004e8c',
                borderColor: paymentType === 'deposit' ? '#004e8c' : '#ddd',
                fontWeight: 'bold',
                fontSize: isMobile ? '0.85rem' : '1rem',
                transition: 'transform 0.3s ease, background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#004e8c',
                  color: '#FFFFFF',
                  transform: 'scale(1.05)',
                },
              }}
            >
              Anzahlung
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}
    </Box>
  );
};

export default PaymentOptions;
