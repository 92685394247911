import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import WhatsAppButton from "src/WhatsappButton";
import PlayNaat from "../PlayNaat/index";
import { getStaticHomeApiReset } from "../../reducers";
import { getStaticHomeApi } from "../../services";

const Navigation = () => {
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const { data: homePage } = useSelector((state) => state.getStaticHomeApiReducer);

  const [naats, setNaats] = useState(
    JSON.parse(localStorage.getItem("naatsData")) || null
  );
  useEffect(() => {
    // Fetch API only if Naats are not in localStorage
    if (!naats) {
      dispatch(getStaticHomeApi());
    }
  }, [dispatch, naats]);

  useEffect(() => {
    // Save `homePage[0].Naat` to localStorage if available
    if (homePage?.length > 0 && homePage[0]?.Naats?.length > 0) {
      const fetchedNaats = homePage[0].Naats;
      const storedNaats = JSON.parse(localStorage.getItem("naatsData"));

      // Update localStorage only if Naats are new or different
      if (!storedNaats || JSON.stringify(storedNaats) !== JSON.stringify(fetchedNaats)) {
        localStorage.setItem("naatsData", JSON.stringify(fetchedNaats));
        setNaats(fetchedNaats);
      }
    }
  }, [homePage]);

  useEffect(() => {
    if (location === "/") {
      navigate("/home");
    }
  }, [location, navigate]);

  useEffect(() => {
    // Scroll to the top when location changes
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          background: "white",
        }}
      >
        <Header />
        <Outlet />
        <WhatsAppButton />
        {naats && naats.length > 0 && <PlayNaat Naats={naats} />}
        <Footer />
      </div>
    </>
  );
};

export default Navigation;
