import React, { useState } from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import BabyIcon from '@mui/icons-material/BabyChangingStation';
import GroupIcon from '@mui/icons-material/Group';
import HotelIcon from '@mui/icons-material/Hotel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const InfantsDetails = ({
  selectedActivityId,
  selectedInsuranceIds,
  selectedTransferId,
  infants,
  adults,
  selectedRoom,
  selectedMealPlanId,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Box
      sx={{
        padding: '20px',
        borderRadius: '12px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        marginBottom: '24px',
        textAlign: 'center',
      }}
    >
      {/* Booking Summary Heading */}
      <Typography
        variant="h4"
        sx={{
          color: '#004e8c',
          fontWeight: 'bold',
          marginBottom: '16px',
          textAlign: 'center',
        }}
      >
        Buchungsübersicht
      </Typography>

     

      <Button
        variant="contained"
        sx={{
          backgroundColor: '#004e8c',
          color: '#fff',
          fontWeight: 'bold',
          transition: 'all 0.3s',
          '&:hover': {
            backgroundColor: '#003b6e',
            transform: 'scale(1.05)',
          },
        }}
        onClick={toggleDetails}
      >
        {showDetails ? 'Details verbergen' : 'Details anzeigen'}
      </Button>

      {showDetails && (
        <Box
          sx={{
            marginTop: '20px',
            padding: '20px',
            borderRadius: '12px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Grid container spacing={2}>
            {/* Room Type in Booking Details */}
           

            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  color: '#333',
                  fontWeight: 'bold',
                }}
              >
                <HotelIcon sx={{ marginRight: '8px', color: '#004e8c' }} />
                Zimmertyp: {selectedRoom?.RoomTypes}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  color: '#333',
                  fontWeight: 'bold',
                }}
              >
                <BabyIcon sx={{ marginRight: '8px', color: '#004e8c' }} />
                Säuglinge: {infants}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  color: '#333',
                  fontWeight: 'bold',
                }}
              >
                <GroupIcon sx={{ marginRight: '8px', color: '#004e8c' }} />
                Erwachsene: {adults}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: '16px' }}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  color: selectedActivityId ? '#2e7d32' : '#d32f2f',
                  fontWeight: 'bold',
                }}
              >
                Aktivitäts:{' '}
                {selectedActivityId ? (
                  <CheckCircleIcon sx={{ marginLeft: '8px' }} />
                ) : (
                  <CancelIcon sx={{ marginLeft: '8px' }} />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  color: selectedInsuranceIds && selectedInsuranceIds.length > 0 ? '#2e7d32' : '#d32f2f',
                  fontWeight: 'bold',
                }}
              >
                Versicherungs:{' '}
                {selectedInsuranceIds && selectedInsuranceIds.length > 0 ? (
                  <CheckCircleIcon sx={{ marginLeft: '8px' }} />
                ) : (
                  <CancelIcon sx={{ marginLeft: '8px' }} />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  color: selectedTransferId ? '#2e7d32' : '#d32f2f',
                  fontWeight: 'bold',
                }}
              >
                Transfer:{' '}
                {selectedTransferId ? (
                  <CheckCircleIcon sx={{ marginLeft: '8px' }} />
                ) : (
                  <CancelIcon sx={{ marginLeft: '8px' }} />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  color: selectedMealPlanId ? '#2e7d32' : '#d32f2f',
                  fontWeight: 'bold',
                }}
              >
                Mahlplan:{' '}
                {selectedMealPlanId ? (
                  <CheckCircleIcon sx={{ marginLeft: '8px' }} />
                ) : (
                  <CancelIcon sx={{ marginLeft: '8px' }} />
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default InfantsDetails;
